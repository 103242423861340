import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/esm/Image';

import LoadingButton from '@/components/loading-button';
import SelectLanguage from '@/components/select-language';
import useTranslate from '@/hooks/useTranslate';
import { ROUTES } from '@/shared/constants';
import { setToaster } from '@/state/core';
import { loginThunk, selectUser, changePasswordThunk } from '@/state/user';
import { Row, Col } from 'react-bootstrap';
import SerieALogo from '@/images/logo-192.jpg';
import CoppaItaliaLogo from '@/images/italian-cup-192.jpg';
import SuperCoppaLogo from '@/images/supercup-192.jpg';
import CorneredContainer from '../cornered-container';
import useLocalStorage from '@/hooks/useLocalStorage';
import { validations } from '@/shared/utils';

function Login() {
    const storage = useLocalStorage();
    const translate = useTranslate();

    const user = useSelector(selectUser);

    const dispatch = useDispatch();
    const history = useHistory();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [email, setEmail] = useState('');

    function usernameChangeHandler({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        setUsername(value);
    }

    function passwordChangeHandler({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        setPassword(value);
    }

    function termsAndConditionsChangeHandler({ target: { checked } }: ChangeEvent<HTMLInputElement>) {
        setTermsAndConditions(checked);
    }

    const isValid = username && password && termsAndConditions;

    useEffect(function() {
        const userMustResetPassword = user.value?.auth?.mustResetPassword;
        if (userMustResetPassword) {
            history.push(ROUTES.RESET_PASSWORD);
        } else {
            if (user.value?.auth?.token) {
                storage.setToken(user.value?.auth?.token);
                history.push(ROUTES.HOME);
            }
        }

        if (!user.value?.changePassword?.loading && !user.value?.changePassword?.changePasswordError && user.value?.changePassword?.changePasswordUrl) {
            window.location.href = user.value?.changePassword?.changePasswordUrl;
        }

        if (user.error) {
            dispatch(setToaster({ message: translate.ERRORS.LOGIN, level: 'danger' }));
        }
    }, [user]);

    function loginHandler() {
        dispatch(loginThunk({ username, password }));
    }

    const emailChangeHandler = (e : ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    function changePasswordHandler() {
        dispatch(changePasswordThunk(email));
    }

    return (
        <CorneredContainer className="login mt-5" topLeft bottomRight>
            <Row>
                <Col className="login-header">
                    <div className="login-title custom-font-weight-medium"><label className="p-1 m-0">{translate.LOGIN_TITLE}</label></div>
                    <div className="login-subtitle custom-font-weight-light">{translate.LOGIN_SUBTITLE}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                <Form onSubmit={loginHandler}>
                    <Form.Group controlId="username" className="input-container">
                        <Form.Control
                            autoComplete="username"
                            name="username"
                            onChange={usernameChangeHandler}
                            placeholder={translate.USERNAME}
                            type="text"
                            className='custom-font-weight-light'
                        />
                    </Form.Group>
                    <Form.Group controlId="password" className="input-container">
                        <Form.Control
                            autoComplete="password"
                            name="password"
                            onChange={passwordChangeHandler}
                            placeholder={translate.PASSWORD}
                            type="password"
                            className='custom-font-weight-light'
                        />
                    </Form.Group>
                    <Form.Group controlId="termsAndConditions" className="terms-and-conditions custom-font-weight-light">
                        <Form.Check
                            checked={termsAndConditions}
                            id="termsAndConditions"
                            label={translate.DISCLAIMER}
                            onChange={termsAndConditionsChangeHandler}
                            type="checkbox"
                        />
                    </Form.Group>
                    <div className="d-flex justify-content-center">
                        <LoadingButton
                            disabled={!isValid}
                            loading={user.loading}
                            onClick={loginHandler}
                            text={translate.LOGIN}
                            variant="secondary"
                            className='custom-font-weight-medium'
                        />
                    </div>
                    <hr className="border-light" />
                    <Form.Group controlId="select-language" className="login-language">
                        <label className='custom-font-weight-light'>{translate.SELECT_LANGUAGE}:</label><br />
                        <SelectLanguage />
                    </Form.Group>
                    <div className="change-password">
                        <label className='custom-font-weight-light'>{translate.PROFILE_LABELS.CHANGE_PASSWORD_LABEL}:</label><br />
                        <input type='email'
                                    placeholder={translate.PROFILE_LABELS.CHANGE_PASSWORD_PLACEHOLDER}
                                    disabled={user.value?.changePassword?.loading}
                                    onChange={emailChangeHandler}
                                    className='email custom-font-weight-medium px-3 py-2'
                                />
                            <LoadingButton
                                    loading={user.value?.changePassword?.loading}
                                    disabled={!validations.email(email)}
                                    onClick={changePasswordHandler}
                                    text={translate.PROFILE_LABELS.CHANGE_PASSWORD_BUTTON}
                                    variant="secondary"
                                    className='custom-font-weight-medium mx-2'
                                />
                        {user.value?.changePassword?.changePasswordError?.message &&
                            <div className='text-warning text-center'>{translate.GENERIC_ERROR}</div>
                        }
                    </div>
                    <div className="d-flex flex-row justify-content-center login-logos">
                        <div className="p-1"><Image src={CoppaItaliaLogo} className="secondary-logo" /></div>
                        <div className="p-1"><Image src={SerieALogo} className="primary-logo" /></div>
                        <div className="p-1"><Image src={SuperCoppaLogo} className="secondary-logo" /></div>
                    </div>
                </Form>
                </Col>
            </Row>
        </CorneredContainer>
    );
}

export default Login;