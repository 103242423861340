/*** Configuration constants by environment */
interface IEnvironments {
    LOCAL: string;
    TEST: string;
    STAGING: string;
    PROD: string;
}
const DEFAULT_ENVIRONMENT = 'PROD';

// REST API
const version = 1;
const apiEnvironments: IEnvironments = {
    LOCAL: 'https://localhost:5001/',
    TEST: 'TODO',
    STAGING: 'https://mpa-web-api-staging.azurewebsites.net/',
    PROD: 'https://mpa-web-api.azurewebsites.net/'
};

export type ValidPaths = 'auth/login' | 'user/password' | 'calendar' | 'GetDownloadLink' | 'GetDownloadStatistics' | 'PurgeCache';
export type ValidMethods = 'GET' | 'POST' | 'PATCH';

export interface IFetch {
    body?: Record<string, any>;
    method?: ValidMethods;
    path: ValidPaths;
    queryString?: Record<string, any>;
    useToken?: boolean;
}

const currentEnvironment = (process.env.REACT_APP_API_ENV || DEFAULT_ENVIRONMENT) as keyof IEnvironments;

export const baseApiUrl = `${(apiEnvironments[(currentEnvironment)])}api/v${version}/`;

// Reset password redirect url
const redirectUrls : IEnvironments = {
    LOCAL: 'http://localhost:3000/',
    TEST: 'TODO',
    STAGING: 'https://yellow-sky-0407f3303.azurestaticapps.net/',
    PROD: 'https://content.seriea.media/'
};

export const resetPasswordRedirectUrl = redirectUrls[currentEnvironment];