import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useState } from 'react';

import { changePasswordThunk, selectUser } from '@/state/user';

import Loading from '@/components/loading';
import SelectLanguage from '@/components/select-language';
import useTranslate from '@/hooks/useTranslate';
import LoadingButton from '../loading-button';
import { validations } from '@/shared/utils';

function Profile() {
    const user = useSelector(selectUser);
    const translate = useTranslate();
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');

    if (user.loading || !user.value) {
        return <Loading />;
    }

    if (user.error) {
        return <div>{user.error}</div>;
    }

    if (!user.value?.changePassword?.loading && !user.value?.changePassword?.changePasswordError && user.value?.changePassword?.changePasswordUrl) {
        window.location.href = user.value?.changePassword?.changePasswordUrl;
    }

    const emailChangeHandler = (e : ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const changePasswordHandler= () => {
        dispatch(changePasswordThunk(email));
    };

    return (
        <div className="d-flex flex-column align-items-center profile">
            <div className="profile-card">
                <div className="profile-header text-center custom-font-weight-medium"><span>{user.value.Name}</span></div>
                <div className="profile-content p-5">
                    <ul>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.CATEGORY}: <span className='custom-font-weight-medium'>{user.value.Category}</span></li>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.CATEGORY_NAME}: <span className='custom-font-weight-medium'>{user.value.CategoryName}</span></li>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.CHAMPIONSHIPS}: <span className='custom-font-weight-medium'>{user.value.Championships}</span></li>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.CONTENTTYPES}: <span className='custom-font-weight-medium'>{user.value.ContentTypes}</span></li>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.EXCLUSIVE_INCLUSION_MATCHES}: <span className='custom-font-weight-medium'>{user.value.ExclusiveInclusionMatches}</span></li>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.TEAMS}: <span className='custom-font-weight-medium'>{user.value.Teams}</span></li>
                        <li><hr /></li>
                        <li className="language custom-font-weight-regular">{translate.SELECT_LANGUAGE}: <SelectLanguage /></li>
                        <li><hr /></li>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.CHANGE_PASSWORD_LABEL}:<br />
                            <input type='email'
                                placeholder={translate.PROFILE_LABELS.CHANGE_PASSWORD_PLACEHOLDER}
                                disabled={user.value?.changePassword?.loading}
                                onChange={emailChangeHandler}
                                className='email custom-font-weight-medium px-3 py-2'
                            />
                            <LoadingButton
                                loading={user.value?.changePassword?.loading}
                                disabled={!validations.email(email)}
                                onClick={changePasswordHandler}
                                text={translate.PROFILE_LABELS.CHANGE_PASSWORD_BUTTON}
                                variant="secondary"
                                className='custom-font-weight-medium'
                            />
                            {user.value?.changePassword?.changePasswordError?.message &&
                                <div className='text-warning'>{translate.GENERIC_ERROR}</div>
                            }
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Profile;